<template>
  <b-modal
    id="noc-view-modal"
    title="NOC View"
    centered
    hide-footer
    size="xl"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">NOC Details</h2>
    </template>

    <b-form @submit.prevent>
      <!-- <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="modal-size"
      > -->
      <b-tabs content-class="py-1" fill>
        <validation-observer ref="nocEditValidation">
          <b-tab no-body>
            <div>
              <b-row>
                <b-col md="3">
                  <b-form-group>
                    <label>CNIC</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="cnic"
                      rules="required|integer|positive|length:13"
                    >
                      <b-form-input
                        id="cnic"
                        v-model="cnic"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        placeholder="1234567890001"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group>
                    <label>Name</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="name"
                        v-model="name"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        placeholder="Name"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label>Contact No.</label>
                    <!-- <span class="text-danger">*</span> -->
                    <validation-provider
                      #default="{ errors }"
                      name="Contact No"
                      rules="integer|length:11"
                    >
                      <b-form-input
                        id="contactNo"
                        v-model="contactNo"
                        disabled
                        :state="errors.length > 0 ? false : null"
                        placeholder="Contact No"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label-for="district">
                    <validation-provider
                      #default="{ errors }"
                      name="District"
                      rules="required"
                    >
                      <label>District</label>
                      <v-select
                        v-model="district"
                        placeholder="District"
                        :options="getDistricts"
                        label="name"
                        class="v-style-chooser"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6">
                  <b-form-group>
                    <label>Address</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="Address"
                      rules="required"
                    >
                      <b-form-input
                        id="address"
                        v-model="address"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Address"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="3" class="mt-1">
                  <b-form-group
                    label-for="isCommercial"
                    v-slot="{ ariaDescribedby }"
                  >
                    <label>Residential / Commercial</label>
                    <b-form-radio-group
                      id="isCommercial"
                      v-model="isCommercial"
                      :aria-describedby="ariaDescribedby"
                      disabled
                    >
                      <b-form-radio v-model="isCommercial" :value="false">
                        Residential
                      </b-form-radio>
                      <b-form-radio v-model="isCommercial" :value="true">
                        Commercial
                      </b-form-radio>
                    </b-form-radio-group>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <label>DHA</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="plot_society"
                      rules="required"
                    >
                      <v-select
                        v-model="plot_society"
                        :options="getDHAs"
                        placeholder="Society"
                        label="name"
                        class="v-style-chooser"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Plot Size"
                      rules="required"
                    >
                      <label>Plot Size</label><span class="text-danger">*</span>
                      <v-select
                        v-model="plot_size"
                        :options="getPlotSize"
                        placeholder="Plot Size"
                        label="name"
                        class="v-style-chooser"
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="3">
                  <b-form-group>
                    <label>Plot No.</label><span class="text-danger">*</span>
                    <validation-provider
                      #default="{ errors }"
                      name="plot_no"
                      rules="required"
                    >
                      <b-form-input
                        id="plot_no"
                        v-model="plot_no"
                        placeholder="Plot No."
                        disabled
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group>
                    <label>Street/Sec/Phase</label
                    ><span class="text-danger">*</span>
                    <b-form-input
                      id="street_sec_p"
                      v-model="street_sec_p"
                      placeholder="Street/Sec/Phase"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </b-tab>
          <b-form-group>
            <!-- <b-button
              type="submit"
              variant="outline-success"
              pill
              class="mr-1 text-left"
              @click="saveAsDraft"
            >
              Save as Draft
            </b-button> -->
            <!-- <b-button
              type="submit"
              variant="primary"
              pill
              class="mr-1"
              @click="validationForm"
            >
              Submit
            </b-button> -->
          </b-form-group>
        </validation-observer>
      </b-tabs>
    </b-form>
    <b-overlay :show="show" spinner-variant="primary" no-wrap />
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "../../util";
import { mapActions, mapGetters } from "vuex";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    nocData: Object,
  },
  mixins: [util],
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      required,
      perfectScrollbarSettings: {
        maxScrollbarLength: 100,
        wheelPropagation: false,
        useBothWheelAxes: false,
        suppressScrollX: true,
      },
      title: "NOC",
      subject: "No Object Certificate (NOC) - for Sale of DHA Plot",
      content: "-",
      comment: "",
      draft: false,
      maxCommentCount: 400,
      selectedMarkType: true,
      selectedOrganization: null,
      availableUserList: [],
      markedUserList: [],
      documentError: 0,
      maxFilesCount: 15,
      maxFileSize: 5,
      files: [],
      confidentialityType: false,
      option: {
        theme: "snow",
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, 6, false] }],

            ["bold", "italic", "underline", "strike"], // toggled buttons

            [{ list: "ordered" }, { list: "bullet" }],
            [{ align: [] }],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
            [{ direction: "rtl" }], // text direction

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
          ],
        },
      },
      streetOrLane: [
        { value: "St", text: "Street", name: "Street" },
        { value: "Ln", text: "Lane", name: "Lane" },
      ],
      streetLane: null,
      prefix: "",
      armyNo: "",
      cnic: "",
      name: "",
      address: "",
      district: "",
      contactNo: "",
      benefit_type: null,
      isServing: true,
      isCommercial: false,
      svc_benefit_type: "",
      regiment: "",
      plot_size: "",
      plot_society: "",
      plot_location: "",
      plot_no: "",
      street_sec_p: "",
      detailForm: false,
      type: { value: 5, name: "User Generated AFP" },
      districts: [
        { title: "Abbottabad" },
        { title: "Astore" },
        { title: "Attock" },
        { title: "Awaran" },
        { title: "Badin" },
        { title: "Bagh" },
        { title: "Bahawalnagar" },
        { title: "Bahawalpur" },
        { title: "Bajaur" },
        { title: "Bannu" },
        { title: "Barkhan" },
        { title: "Batagram" },
        { title: "Bhakkar" },
        { title: "Bhimber" },
        { title: "Buner" },
        { title: "Chagai" },
        { title: "Chakwal" },
        { title: "Charsadda" },
        { title: "Chiniot" },
        { title: "Dadu" },
        { title: "Darel" },
        { title: "Dera Bugti" },
        { title: "Dera Ghazi Khan" },
        { title: "Dera Ismail Khan" },
        { title: "Diamer" },
        { title: "Duki" },
        { title: "Faisalabad" },
        { title: "Ghanche" },
        { title: "Ghizer" },
        { title: "Ghotki" },
        { title: "Gilgit" },
        { title: "Gujranwala" },
        { title: "Gujrat" },
        { title: "Gupis Yasin" },
        { title: "Gwadar" },
        { title: "Hafizabad" },
        { title: "Hangu" },
        { title: "Haripur" },
        { title: "Harnai" },
        { title: "Hattian" },
        { title: "Haveli" },
        { title: "Hunza" },
        { title: "Hyderabad" },
        { title: "Islamabad" },
        { title: "Jacobabad" },
        { title: "Jafarabad" },
        { title: "Jamshoro" },
        { title: "Jhal Magsi" },
        { title: "Jhang" },
        { title: "Jhelum" },
        { title: "Kachhi" },
        { title: "Kalat" },
        { title: "Karachi Central" },
        { title: "Karachi East" },
        { title: "Karachi South" },
        { title: "Karachi West" },
        { title: "Karak" },
        { title: "Kashmore" },
        { title: "Kasur" },
        { title: "Kech" },
        { title: "Khairpur" },
        { title: "Khanewal" },
        { title: "Kharan" },
        { title: "Kharmang" },
        { title: "Khushab" },
        { title: "Khuzdar" },
        { title: "Khyber" },
        { title: "Killa Abdullah" },
        { title: "Kohat" },
        { title: "Kohlu" },
        { title: "Kolai Pallas" },
        { title: "Korangi" },
        { title: "Kotli" },
        { title: "Kurram" },
        { title: "Lahore" },
        { title: "Lakki Marwat" },
        { title: "Larkana" },
        { title: "Lasbela" },
        { title: "Layyah" },
        { title: "Lodhran" },
        { title: "Loralai" },
        { title: "Lower Chitral" },
        { title: "Lower Dir" },
        { title: "Lower Kohistan" },
        { title: "Malakand" },
        { title: "Malir" },
        { title: "Mandi Bahauddin" },
        { title: "Mansehra" },
        { title: "Mardan" },
        { title: "Mastung" },
        { title: "Matiari" },
        { title: "Mianwali" },
        { title: "Mirpur Khas" },
        { title: "Mirpur" },
        { title: "Mohmand" },
        { title: "Multan" },
        { title: "Musakhel" },
        { title: "Muzaffarabad" },
        { title: "Muzaffargarh" },
        { title: "Nagar" },
        { title: "Nankana Sahib" },
        { title: "Narowal" },
        { title: "Naseerabad" },
        { title: "Naushahro Firoze" },
        { title: "Neelum" },
        { title: "North Waziristan" },
        { title: "Nowshera" },
        { title: "Nushki" },
        { title: "Okara" },
        { title: "Orakzai" },
        { title: "Pakpattan" },
        { title: "Panjgur" },
        { title: "Peshawar" },
        { title: "Pishin" },
        { title: "Poonch" },
        { title: "Qambar Shahdadkot" },
        { title: "Qilla Saifullah" },
        { title: "Quetta" },
        { title: "Rahim Yar Khan" },
        { title: "Rajanpur" },
        { title: "Rawalpindi" },
        { title: "Roundu" },
        { title: "Sahiwal" },
        { title: "Sanghar" },
        { title: "Sargodha" },
        { title: "Shaheed Benazirabad" },
        { title: "Shaheed Sikandarabad" },
        { title: "Shangla" },
        { title: "Sheikhupura" },
        { title: "Sherani" },
        { title: "Shigar" },
        { title: "Shikarpur" },
        { title: "Sialkot" },
        { title: "Sibi" },
        { title: "Skardu" },
        { title: "Sohbatpur" },
        { title: "South Waziristan" },
        { title: "Sudhnutti" },
        { title: "Sujawal" },
        { title: "Sukkur" },
        { title: "Swabi" },
        { title: "Swat" },
        { title: "Tando Allahyar" },
        { title: "Tando Muhammad Khan" },
        { title: "Tangir" },
        { title: "Tank" },
        { title: "Tharparkar" },
        { title: "Thatta" },
        { title: "Toba Tek Singh" },
        { title: "Tor Ghar" },
        { title: "Umerkot" },
        { title: "Upper Chitral" },
        { title: "Upper Dir" },
        { title: "Upper Kohistan" },
        { title: "Vehari" },
        { title: "Washuk" },
        { title: "Zhob" },
        { title: "Ziarat" },
      ],
      plotSizes: [
        { title: "3 marla" },
        { title: "5 marla" },
        { title: "10 marla" },
        { title: "1 kanal" },
        { title: "2 kanal" },
      ],

      corps: [
        { title: "FF Regiment" },
        { title: "Baloch Regiment" },
        { title: "Sindh Regiment" },
        { title: "Punjab Regiment" },
        { title: "NLI Regiment" },
        { title: "AK Regiment" },
        { title: "Armoured Corps" },
        { title: "Army Aviation Corps" },
        { title: "Army Medical Corps" },
        { title: "Ordnance Corps" },
        { title: "AS & T" },
        { title: "RV & FC" },
        { title: "Engineers Corps" },
        { title: "EME Corps" },
        { title: "Signals Corps" },
        { title: "Artillery Corps" },
        { title: "Mujahid Regiment" },
        { title: "AFNS" },
      ],
      rankList: [
        { title: "Lt Gen" },
        { title: "Maj Gen" },
        { title: "Brig" },
        { title: "Col" },
        { title: "Lt Col" },
        { title: "Maj" },
        { title: "Capt" },
        { title: "Lt" },
        { title: "2nd Lt" },
        { title: "SM" },
        { title: "Sub" },
        { title: "N Sub" },
        { title: "Hav" },
        { title: "Nk" },
        { title: "Lnk" },
        { title: "Sep" },
      ],
      benefitTypeList: [
        {
          title: "Svc Benefit",
        },
        {
          title: "QMG Plot",
        },
        {
          title: "In lieu of QMG Plot",
        },
        {
          title: "Additional Benefit",
        },
        {
          title: "Appt Benefit",
        },
        {
          title: "Incentive Benefit",
        },
        {
          title: "Compensatory Benefit",
        },
        {
          title: "Rehab",
        },
        {
          title: "On Compassionate Gr",
        },
        {
          title: "Open Ballot",
        },
        {
          title: "In lieu of QMG",
        },
        {
          title: "In lieu of House",
        },
        {
          title: "Before Policy",
        },
      ],
      societyList: [
        { title: "DHA-Rawalpindi" },
        { title: "DHA-Karachi" },
        { title: "DHA-Sargodah" },
        { title: "DHA-Multan" },
        { title: "DHA-Lahore" },
        { title: "DHA-Quetta" },
      ],
      benefitNumbers: [
        { title: "First" },
        { title: "Second" },
        { title: "Third" },
        { title: "Fourth" },
      ],
      // svcStatusList: [],

      priority: { value: "Normal", name: "Normal" },
      priorities: [
        { value: "Normal", name: "Normal" },
        { value: "Flash", name: "Flash" },
      ],
    };
  },
  async mounted() {
    if (this.nocData) {
      this.cnic = this.nocData.cnic;
      this.name = this.nocData.name;
      this.contactNo = this.nocData.contact_no;
      this.address = this.nocData.address;
      this.district = this.nocData.district;
      this.benefit_type = this.nocData.benefit_type;
      this.svc_benefit_type = this.nocData.svc_benefit_type;
      this.regiment = this.nocData.regiment;
      this.plot_size = this.nocData.plot_size;
      this.plot_society = this.nocData.plot_society;
      this.plot_location = this.nocData.plot_location;
      this.plot_no = this.nocData.plot_no;
      this.street_sec_p = this.nocData.street_sec_p;
      this.isServing = this.nocData.is_serving;
      this.isCommercial = this.nocData.is_commercial;
      this.draft = false;

      this.currently_marked = 99999999;
    }
  },

  methods: {
    ...mapActions({
      partialUpdateNoc: "appData/partialUpdateNoc",
    }),

    async submit() {
      try {
        this.show = true;
        const res = await this.partialUpdateNoc({
          id: this.nocData.id,
          title: this.title,
          subject: this.subject,
          content: this.content,
          type: this.type.value,
          draft: this.draft,
          history: history,

          cnic: this.cnic,
          name: this.name,
          address: this.address,
          district: this.district.value,
          contact_no: this.contactNo,

          benefit_type: this.benefit_type.value,
          svc_benefit_type: this.svc_benefit_type.value,
          regiment: this.regiment.value,
          plot_size: this.plot_size.value,
          plot_society: this.plot_society.value,
          plot_location: this.plot_location,
          plot_no: this.plot_no,
          street_sec_p: this.street_sec_p,

          is_serving: this.isServing,

          is_commercial: this.isCommercial,
          draft: this.draft,

          currently_marked: 99999999,
          priority: this.priority.value,
        });
        if (res.status === 200) {
          this.$swal({
            title: res.data.msg,
            icon: "success",
            timer: 1500,
          });
          this.show = false;
          this.$nextTick(() => {
            this.$bvModal.hide("noc-edit-modal");
          });
          this.$emit("modalClosed");
        } else {
          this.$swal({
            title: "Unable to update NOC",
            icon: "error",
            timer: 3000,
          });
        }
      } catch (error) {
        this.show = false;
        this.displayError(error);
      }
    },
    async validationForm() {
      const success = await this.$refs.nocEditValidation.validate();
      if (success) {
        await this.submit();
      } else {
        this.$swal({
          title: "Please fill out all required fields",
          icon: "error",
          timer: 3000,
        });
      }
    },

    async saveAsDraft() {
      this.draft = true;
      await this.validationForm();
    },
  },
  computed: {
    ...mapGetters({
      getLoggedInUser: "appData/getUser",
    }),
    getPrefixes() {
      return this.prefixList.map((type) => {
        return {
          value: type.prefix,
          name: type.prefix,
        };
      });
    },

    getCorps() {
      return this.corps.map((type) => {
        return {
          value: type.title,
          name: type.title,
        };
      });
    },
    getBenefitNumbers() {
      return this.benefitNumbers.map((type) => {
        return {
          value: type.title,
          name: type.title,
        };
      });
    },

    getBenefitTypes() {
      return this.benefitTypeList.map((type) => {
        return {
          value: type.title,
          name: type.title,
        };
      });
    },

    getDistricts() {
      return this.districts.map((type) => {
        return {
          value: type.title,
          name: type.title,
        };
      });
    },

    getPlotSize() {
      return this.plotSizes.map((type) => {
        return {
          value: type.title,
          name: type.title,
        };
      });
    },

    getDHAs() {
      return this.societyList.map((type) => {
        return {
          value: type.title,
          name: type.title,
        };
      });
    },
  },
};
</script>

<style scoped>
[hidden] {
  display: none !important;
}

.wrapper {
  position: relative;
}

.overlay {
  position: absolute;
  bottom: 0px;
  text-align: center;
}

/* label {
  font-size: 110%;
  font-weight: 550 !important;
  color: #000;
} */
</style>
