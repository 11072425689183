<template>
  <div class="text-black">
    <!-- <div class="text-right mb-1">
      <b-button variant="primary" pill @click="createNoc">
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
      </b-button>
    </div> -->

    <div class="mb-1">
      <b-card>
          <noc-submit-modal @modalClosed="onModalClosed" />
      </b-card>
    </div>
    <b-card>
      <b-card-body class="mt-2">
          <b-row class="mt-2 mb-2"
                 style="border: 1px solid #ccc; border-radius: 5px; padding: 10px"
                 v-if="hasRole('op') || hasRole('su') || hasRole('op_admin')">
              <b-col md="2">
                  <b-form-group>
                      <label> CNIC </label>
                      <b-form-input id="cnic"
                                    v-model="cnic"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    placeholder="CNIC"
                                    class="text-black" />
                  </b-form-group>
              </b-col>
              <b-col md="2">
                  <b-form-group>
                      <label> Name </label>
                      <b-form-input id="Name"
                                    v-model="name"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    placeholder="Name"
                                    class="text-black" />
                  </b-form-group>
              </b-col>
              <b-col class="text-right mb-1" style="margin: auto">
                  <div style="display: flex; justify-content: flex-end">
                      <b-button variant="primary"
                                pill
                                style="margin-right: 2%"
                                @click="searchPetitionViaSearchButton">
                          <feather-icon icon="SearchIcon" class="mr-50" />
                          Search
                      </b-button>
                  </div>
              </b-col>
          </b-row>

        <b-table v-if="hasRole('op') || hasRole('su') || hasRole('op_admin')"
                 responsive="sm"
                 :fields="fields_admin"
                 :paginated="true"
                 :items="nocs"
                 details-td-class="m-0 p-0"
                 :tbody-tr-class="rowClass"
                 sticky-header="700px"
                 small
                 class="text-black">
            <template #cell(selected)="row">
                <b-form-checkbox v-model="row.item.selected"
                                 v-if="hasRole('op') || hasRole('su') || hasRole('op_admin')"
                                 @change="onSelected(row.item)">
                </b-form-checkbox>
            </template>

            <template #cell(is_received)="row">
                <span>
                    {{ row.item.is_received ? "Yes" : "No" }}
                </span>
            </template>
            <template #cell(draft)="row">
                <span>
                    {{ row.item.draft ? "Yes" : "No" }}
                </span>
            </template>

            <template #cell(manage)="row">
                <b-button variant="secondary"
                          pill
                          size="sm"
                          v-if="hasRole('su')"
                          @click="viewNoc(row.item)">
                    View
                </b-button>
                <b-button v-if="row.item.document_details.length !== 0 && hasRole('su')"
                          variant="info"
                          @click="showNocDocuments(row.item)"
                          pill
                          size="sm">
                    Documents
                </b-button>


                <b-button variant="success"
                          pill
                          style="margin-right: 2%; margin-left: 2%"
                          size="sm"
                          @click="receiveSelectedNoc(row.item)"
                          v-if="!row.item.is_received">
                    <feather-icon icon="CheckIcon" class="mr-50" />
                    Receive
                </b-button>
                <b-button variant="warning"
                          pill
                          size="sm"
                          style="margin-right: 2%"
                          @click="changeStatustoClose(row.item)"
                          v-if="row.item.status!==3">
                    Confirm Close
                </b-button>
            </template>
        </b-table>

        <b-table v-else
                 responsive="sm"
                 :fields="fields_normal_user"
                 :paginated="true"
                 :items="nocs"
                 details-td-class="m-0 p-0"
                 :tbody-tr-class="rowClass"
                 sticky-header="700px"
                 small
                 class="text-black">

            <template #cell(is_received)="row">
                <span>
                    {{ row.item.is_received ? "Yes" : "No" }}
                </span>
            </template>
            <template #cell(draft)="row">
                <span>
                    {{ row.item.draft ? "Yes" : "No" }}
                </span>
            </template>

            <template #cell(manage)="row">
                <b-button variant="secondary"
                          pill
                          size="sm"
                          @click="viewNoc(row.item)">
                    View
                </b-button>
                <b-button v-if="row.item.document_details.length !== 0"
                          variant="info"
                          @click="showNocDocuments(row.item)"
                          pill
                          size="sm">
                    Documents
                </b-button>


            </template>
        </b-table>
        <b-row>
          <b-col md="8">
              <b-pagination v-model="currentPage"
                            :total-rows="totalDataLength"
                            :per-page="per_page" />
          </b-col>
          <b-col md="4">
              <div style="float: right">
                  <h5 style="margin: 0; display: inline" class="text-primary">
                      Count:
                  </h5>
                  <h5 style="margin: 0; display: inline" align="right">
                      <strong>
                          {{ totalDataLength }}
                      </strong>
                  </h5>
              </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- <NocSubmitModal @modalClosed="onModalClosed" :key="`create-${NocSubmitModalCount}`" /> -->
    <NocDocumentsModal
      :nocDocuments="nocDocuments"
      :key="`document-${nocDocumentModalCount}`"
      :nocID="nocID"
      :nocDraft="nocDraft"
      @modalClosed="onModalClosedDocuments"
    />
    <NocViewModal
      :nocData="nocViewData"
      :key="`edit-${nocViewModalCount}`"
      :nocID="nocID"
      @modalClosed="onModalClosedEdit"
    />
  </div>
</template>
<script>
    import { mapActions, mapGetters } from "vuex";
    import util from "@/util.js";
    import NocSubmitModal from "@/components/noc/NocSubmitModal.vue";
    import NocDocumentsModal from "@/components/noc/NocDocumentsModal.vue";
    import NocViewModal from "@/components/noc/NocViewModal.vue";
    export default {
        components: {
            NocSubmitModal,
            NocDocumentsModal,
            NocViewModal,
        },
        data() {
            return {
                nocDocumentModalCount: 0,
                NocSubmitModalCount: 0,
                nocs: [],
                nocID: null,
                totalDataLength: 0,
                per_page: 0,
                petitionTitle: null,
                downloadStatus: null,
                applicantCNIC: null,
                searchOptions: [
                    { text: "Yes", value: "True" },
                    { text: "No", value: "False" },
                ],
                draftStatus: null,
                applicant_case_no: null,

                show: false,
                nocDocuments: null,
                cnic: null,
                name: null,
                currentPage: 1,
                nocViewModalCount: 0,
                draft: false,
                petitionID: null,
                nocData: null,
                nocDraft: false,
                nocViewData: null,
                selectedItems: [],
                fields_admin: [
                    { key: "cnic", label: "CNIC", sortable: true },
                    { key: "name", label: "Name", sortable: true },
                    { key: "is_received", label: "Received", sortable: true },
                    { key: "contact_no", label: "Contact", sortable: true },
                    { key: "status_name", label: "status", sortable: true },
                    { key: "updated_at", label: "Updated At", sortable: true },
                    {
                        key: "updated_by_data.username",
                        label: "Updated By",
                        sortable: true,
                    },
                    { key: "manage", label: "Manage" },
                ],
                fields_normal_user: [
                    { key: "cnic", label: "CNIC", sortable: true },
                    { key: "name", label: "Name", sortable: true },
                    { key: "status_name", label: "status", sortable: true },
                    { key: "is_received", label: "Received", sortable: true },
                    { key: "contact_no", label: "Contact", sortable: true },
                    { key: "manage", label: "Manage" },
                ],
            };
        },
        mixins: [util],
        async mounted() {
            await this.getNocsData();
        },
        methods: {
            ...mapActions({
                getNocs: "appData/getNocs",
                deleteNoc: "appData/deleteNoc",
                partialUpdateNoc: "appData/partialUpdateNoc",
                update_status_to_close_send_sms: "appData/update_status_to_close_send_sms",
            }),
            async changeStatustoClose(petition) {
                try {
                    this.show = true;
                    await this.$swal({
                        title: "Do you want to update the NOC status?",
                        text: "SMS will be sent to the recipient, You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes, close this NOC!",
                        customClass: {
                            confirmButton: "btn btn-success",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then(async (result) => {
                        if (result.value) {
                            const res = await this.update_status_to_close_send_sms({
                                type: 'NOC',
                                id: petition.id,
                            });
                            if (res.status === 200) {
                                this.$swal({
                                    title: res.data.msg,
                                    icon: "success",
                                    timer: 3500,
                                });
                            }
                            await this.getNocsData();
                        }
                    });
                    this.show = false;
                } catch (error) {
                    this.displayError(error);
                }
            },
            onSelected(item) {
                if (this.selectedItems.includes(item)) {
                    const index = this.selectedItems.indexOf(item);
                    this.selectedItems.splice(index, 1);
                } else {
                    this.selectedItems.push(item);
                }
                if (this.selectedItems && this.selectedItems.length) {
                    this.selectedItems.forEach((obj) => { });
                }
            },
            async searchPetitionViaSearchButton() {
                this.currentPage = 1;
                await this.searchPetitionWithFilters();
            },
            createNoc() {
                this.NocSubmitModalCount += 1;
                this.$nextTick(() => {
                    this.$bvModal.show("noc-submit-modal");
                });
            },
            async onModalClosed() {
                this.$bvModal.hide("noc-submit-modal");
                await this.getNocsData();
            },
            async onModalClosedDocuments() {
                this.$bvModal.hide("noc-documents-modal");
                await this.getNocsData();
            },

            async onModalClosedEdit() {
                this.$bvModal.hide("noc-edit-modal");
                await this.getNocsData();
            },
            async searchPetitionWithFilters() {
                let payload = {};
                payload["pageNumber"] = this.currentPage;
                if (this.name !== null) {
                    payload["name"] = this.name;
                }
                if (this.cnic !== null) {
                    payload["cnic"] = this.cnic;
                }
                await this.getNocsData(payload);
            },
            viewNoc(item) {
                this.nocViewModalCount += 1;
                this.nocViewData = item;
                this.$nextTick(() => {
                    this.$bvModal.show("noc-view-modal");
                });
            },
            showNocDocuments(noc) {
                this.nocDocumentModalCount += 1;
                this.nocDocuments = noc.document_details;
                this.nocID = noc.id;
                this.nocDraft = noc.draft;
                this.$nextTick(() => {
                    this.$bvModal.show("noc-documents-modal");
                });
            },
            async deleteSelectedNoc(item) {
                try {
                    this.show = true;
                    await this.$swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes, delete it!",
                        customClass: {
                            confirmButton: "btn btn-danger",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then(async (result) => {
                        if (result.value) {
                            const res = await this.deleteNoc({ id: item.id });
                            if (res.status === 200) {
                                this.$swal({
                                    title: res.data.msg,
                                    icon: "success",
                                    timer: 1500,
                                });
                            } else {
                                this.$swal({
                                    title: "Unable to delete Noc",
                                    icon: "error",
                                    timer: 3000,
                                });
                            }
                        }
                    });
                    this.show = false;
                    await this.getNocsData();
                } catch (error) {
                    this.displayError(error);
                }
            },

            async receiveSelectedNoc(noc) {
                try {
                    this.show = true;
                    await this.$swal({
                        title: "Are you sure?",
                        text: "You won't be able to revert this!",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Yes, receive it!",
                        customClass: {
                            confirmButton: "btn btn-success",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then(async (result) => {
                        if (result.value) {
                            const res = await this.partialUpdateNoc({
                                id: noc.id,
                                is_received: true,
                            });
                            if (res.status === 200) {
                                this.$swal({
                                    title: res.data.msg,
                                    icon: "success",
                                    timer: 1500,
                                });
                            } else {
                                this.$swal({
                                    title: "Unable to mark selected noc as received",
                                    icon: "error",
                                    timer: 3000,
                                });
                            }
                            await this.getNocsData();
                        }
                    });
                    this.show = false;
                } catch (error) {
                    this.displayError(error);
                }
            },
            rowClass(item, type) {
                // const colorClassLaw = "table-success";
                // const colorClassLaw = "border-dark";

                const colorClassLaw = "lawDTEColorClass";
                const colorClassPS = "table-success";
                const colorClasshousing = "table-warning";
                // const colorClassWR = "table-danger";
                const colorClassWR = "WRDTEColorClass";
                // const colorClassAG = "table-dark";
                const colorClassAG = "AGBranchColorClass";
                const colorClassCOAS = "table-info";
                const colorClassMed = "MedColorClass";
                const colorClassPA = "table-danger";
                const colorClassPPA = "PPADTEColorClass";
                const colorClassJAG = "JAGDTEColorClass";
                const colorClassRegs = "REGSColorClass";
                const colorClassCP = "CPColorClass";
                if (!item || type !== "row") {
                    return;
                }

                switch (item.marked_to) {
                    case "COAS":
                        return colorClassCOAS;
                    case "AG Branch":
                        return colorClassAG;
                    case "PA":
                        return colorClassPA;
                    case "PS":
                        return colorClassPS;
                    case "W&R":
                        return colorClassWR;
                    case "Med":
                        return colorClassMed;
                    case "Housing":
                        return colorClasshousing;
                    case "PP&A":
                        return colorClassPPA;
                    case "Law":
                        return colorClassLaw;
                    case "CP":
                        return colorClassCP;
                    case "Regs":
                        return colorClassRegs;
                    case "JAG":
                        return colorClassJAG;
                    default:
                        return;
                }
            },
            async getNocsData(searchParams = {}) {
                try {
                    this.show = true;
                    const res = await this.getNocs(searchParams);
                    this.nocs = res.data.results;
                    this.per_page = res.data.per_page;
                    this.totalDataLength = res.data.count;
                    this.show = false;
                } catch (error) {
                    this.show = false;
                    this.displayError(error);
                }
            },
        },
        computed: {
            ...mapGetters({ getUser: "appData/getUser", hasRole: "appData/hasRole" }),
            nocComputedData() {
                try {
                    if (this.nocs) {
                        if (this.nocs.length > 0) {
                            const nocData = [];
                            this.nocs.map((noc) => {
                                nocData.push({
                                    id: noc.id,
                                    name: noc.name,
                                    cnic: noc.cnic,
                                    document_details: noc.document_details,
                                    contact_no: noc.contact_no,
                                    updated_at: noc.updated_at,
                                    updated_by: noc.updated_by,
                                });
                            });
                            return nocData;
                        }
                    }
                } catch (error) {
                    return [];
                }
            },
        },

        watch: {
            currentPage: {
                async handler() {
                    await this.searchPetitionWithFilters();
                },
            },
        },
    };
</script>
<style>
.text-black {
  color: black !important;
  /* font-size: 1.2rem; */
}

label {
  font-size: 110%;
  font-weight: 750 !important;
  
}

</style>

<style lang="scss" scoped>
@import "~@assets/scss/variables/_variables.scss";





.lawDTEColorClass {
  background-color: $light-brown-color-var;
  color: white;
}

.MedColorClass {
  background-color: $light-red-medical-color;
  color: white;
}

.WRDTEColorClass {
  background-color: $WR-DTE-Color;
  color: white;
}

.PPADTEColorClass {
  background-color: $PPA-DTE-Color;
  color: white;
}

.JAGDTEColorClass {
  background-color: $JAG-DTE-Color;
  color: white;
}

.REGSColorClass {
  background-color: $REGS-DTE-Color;
  color: white;
}

.CPColorClass {
  background-color: $CP-DTE-Color;
  color: white;
}

.AGBranchColorClass {
  background-color: $AG-BRANCH-Color;
  color: white;
}
</style>
